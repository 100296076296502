// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-drip-irrigation-js": () => import("./../../../src/pages/dripIrrigation.js" /* webpackChunkName: "component---src-pages-drip-irrigation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tissue-culture-js": () => import("./../../../src/pages/tissueCulture.js" /* webpackChunkName: "component---src-pages-tissue-culture-js" */)
}

